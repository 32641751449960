<template>
  <div class="score-query" v-if="status.enrollStatus">
    <el-row class="score-query-title">
      <el-col>
        <el-icon class="btn" v-if="status.enrollStatus" @click="back">
          <ArrowLeftBold />
        </el-icon>
        <span v-if="status.examStep === -2 && state.examContent">报名流程及注意事项</span>
        <span v-if="status.examStep === -1 && state.examProtocol.proContent">{{
          state.examProtocol.examName
        }}</span>
        <span v-if="status.examStep === 0">报考信息</span>
        <span v-if="status.examStep === 1">基本信息</span>
        <span v-if="status.examStep === 2">学历信息</span>
        <span v-if="status.examStep === 3">简介</span>
        <span v-if="status.examStep === 4">其他信息</span>
        <span v-if="status.examStep === 5">上传照片</span>
        <span v-if="status.examStep === 6">预览提交</span>
        <span v-if="status.examStep === 7">审核资料</span>
        <span v-if="status.examStep === 8">报名完成</span>
      </el-col>
    </el-row>
    <el-row class="message-content">
      <el-col>
        <!-- 报名流程及注意事项 -->
        <div v-if="status.examStep === -2">
          <div style="min-height: 75vh" v-html="state.examContent"></div>
          <div style="text-align: center">
            <el-button type="primary" @click="startEnrollment">开始报名</el-button>
          </div>
        </div>
        <!-- 承诺 -->
        <div v-if="status.examStep === -1">
          <div style="min-height: 75vh" v-html="state.examProtocol.proContent"></div>
          <div style="text-align: center">
            <el-button type="primary" @click="startFilling">我已阅读并同意</el-button>
          </div>
        </div>
        <!-- 步骤条 -->
        <div v-if="status.examStep >= 0">
          <el-steps :active="status.examStep" align-center style="padding: 15px 0 30px 0">
            <el-step title="报考信息" />
            <el-step title="基本信息" />
            <el-step title="学历信息" />
            <el-step title="简介" />
            <el-step title="其他信息" />
            <el-step title="上传照片" />
            <el-step title="预览提交" />
            <el-step title="审核资料" />
            <!-- <el-step title="网上缴费" /> -->
            <el-step title="报名完成" />
          </el-steps>
        </div>
        <!-- 报考信息 -->
        <div v-if="status.examStep === 0 && applicationInformation[0].show">
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item v-if="state.applyInfoStatus.sector === '1'" label="报考单位：" prop="sectorId">
              <el-select v-model="form.sectorId" placeholder="请选择" @change="sectorChange">
                <el-option v-for="(item, index) in state.sectorIdList" :key="item.id" :label="item.name"
                  :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.department === '1'" label="报考部门：" prop="departmentId">
              <el-select v-model="form.departmentId" placeholder="请选择" @change="departmentChange">
                <el-option v-for="(item, index) in state.departmentIdList" :key="item.id" :label="item.name"
                  :value="item.id" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.post === '1'" label="报考岗位：" prop="stationId">
              <el-select v-model="form.stationId" placeholder="请选择" @change="getMajorsAndDegrees">
                <el-option v-for="(item, index) in state.stationList" :key="item.id" :label="item.name"
                  :value="item.id" />
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="status.examStep === 0 && !applicationInformation[0].show" class="errorMessage"
          style="height: 2.0833rem; line-height: 2.0833rem">
          无相关信息选项，请点击下一步
        </div>
        <!-- 基本信息 -->
        <div v-if="status.examStep === 1 && applicationInformation[1].show">
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item v-if="state.applyInfoStatus.name === '1'" label="姓名：" prop="name">
              <el-input v-model="form.name" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.gender === '1'" label="性别：" prop="sex">
              <el-select v-model="form.sex" placeholder="请选择">
                <el-option :key="0" label="男" value="0" />
                <el-option :key="1" label="女" value="1" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.nation === '1'" label="民族：" prop="nation">
              <el-input v-model="form.nation" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.politicStatus === '1'" label="政治面貌：" prop="politicsStatus">
              <el-input v-model="form.politicsStatus" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.identityId === '1'" label="身份证号：" prop="cardId">
              <el-input v-model="form.cardId" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.preEnrolReside === '1'" label="入学前户口所在地：" prop="oldAddress">
              <el-input v-model="form.oldAddress" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.nowEnrolReside === '1'" label="现户口所在地：" prop="newAddress">
              <el-input v-model="form.newAddress" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.fixedPhone === '1'" label="固定电话：" prop="telephone">
              <el-input v-model="form.telephone" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.mobilePhone === '1'" label="本人手机：" prop="mobile">
              <el-input v-model="form.mobile" :disabled="true" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.familyAddress === '1'" label="家庭住址：" prop="familyAddress">
              <el-input v-model="form.familyAddress" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.marryState === '1'" label="婚否：" prop="marryState">
              <el-input v-model="form.marryState" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.marryChangeDate === '1'" label="婚迁日期：">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.marryChangeTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="status.examStep === 1 && !applicationInformation[1].show" class="errorMessage"
          style="height: 2.0833rem; line-height: 2.0833rem">
          无相关信息选项，请点击下一步
        </div>
        <!-- 学历信息 -->
        <div v-if="status.examStep === 2 && applicationInformation[2].show">
          <div v-if="state.applyInfoStatus.firstDegree === '1'" class="info-title">
            学历
          </div>
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item v-if="state.applyInfoStatus.firstDegree === '1'" label="学历：" prop="firstDegree">
              <el-select v-model="form.firstDegree" placeholder="请选择">
                <el-option :label="item" :value="item" v-for="(item, index) in state.degrees" :key="index" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstSchool === '1'" label="毕业院校：" prop="firstSchool">
              <el-input v-model="form.firstSchool" />
            </el-form-item>

            <el-form-item v-if="state.applyInfoStatus.firstMajor === '1'" label="所学专业："  prop="firstMajor">
              <el-select v-model="form.firstMajor" placeholder="请选择" :reserve-keyword="false" filterable allow-create>
                <el-option :label="item" :value="item" v-for="(item, index) in state.majors" :key="index" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstSdate === '1'" label="入学日期：" prop="firstStartTime">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.firstStartTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstEdate === '1'" label="毕业日期：" prop="firstEndTime">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.firstEndTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstEduType === '1'" label="培养类型：" prop="firstType">
              <el-select v-model="form.firstType" placeholder="请选择">
                <el-option :key="1" label="全日制" value="全日制" />
                <el-option :key="2" label="非全日制" value="非全日制" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstConclusion === '1'" label="毕业结论：" prop="firstCon">
              <el-select v-model="form.firstCon" placeholder="请选择">
                <el-option :key="1" label="毕业" value="毕业" />
                <el-option :key="2" label="结业" value="结业" />
                <el-option :key="3" label="肄业" value="肄业" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.firstCertId === '1'" label="毕业证号：" prop="firstNum">
              <el-input v-model="form.firstNum" />
            </el-form-item>
          </el-form>
          <div v-if="state.applyInfoStatus.topDegree === '1'" class="info-title">
            最高学历
          </div>
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item v-if="state.applyInfoStatus.topDegree === '1'" label="最高学历：" prop="topDegree">
              <el-select v-model="form.topDegree" placeholder="请选择">
                <el-option :key="1" label="高中" value="高中" />
                <el-option :key="2" label="专科" value="专科" />
                <el-option :key="3" label="本科" value="本科" />
                <el-option :key="4" label="硕士" value="硕士" />
                <el-option :key="5" label="博士" value="博士" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topSchool === '1'" label="毕业院校：" prop="topSchool">
              <el-input v-model="form.topSchool" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topMajor === '1'" label="所学专业：" prop="topMajor">
              <el-input v-model="form.topMajor" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topSdate === '1'" label="入学日期：" prop="topStartTime">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.topStartTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topEdate === '1'" label="毕业日期：" prop="topEndTime">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.topEndTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topEduType === '1'" label="培养类型：" prop="firstType">
              <el-select v-model="form.topType" placeholder="请选择">
                <el-option :key="1" label="全日制" value="全日制" />
                <el-option :key="2" label="非全日制" value="非全日制" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topConclusion === '1'" label="毕业结论：" prop="topCon">
              <el-select v-model="form.topCon" placeholder="请选择">
                <el-option :key="1" label="毕业" value="毕业" />
                <el-option :key="2" label="结业" value="结业" />
                <el-option :key="3" label="肄业" value="肄业" />
              </el-select>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.topCertId === '1'" label="毕业证号：" prop="topNum">
              <el-input v-model="form.topNum" />
            </el-form-item>
          </el-form>
          <div v-if="state.applyInfoStatus.certName === '1'" class="info-title">
            资格证书
            <!-- <span style="font-size: 12px; color: red;font-weight: 400;">（仅 <span style="font-weight: bold;">会计专业</span> 填写 <span style="font-weight: bold;">无</span> 且发证日期选择 <span style="font-weight: bold;">报名时间</span> ）</span> -->
          </div>
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item v-if="state.applyInfoStatus.certName === '1'" label="资格证名称：" prop="certName">
              <el-input v-model="form.certName" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.subjectName === '1'" label="各类学科：" prop="subjectName">
              <el-input v-model="form.subjectName" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.certNum === '1'" label="资格证号：" prop="certNum">
              <el-input v-model="form.certNum" />
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.certTime === '1'" label="发证日期：" prop="certTime">
              <el-config-provider :locale="zhcn">
                <el-date-picker v-model="form.certTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                  value-format="YYYY-MM-DD" />
              </el-config-provider>
            </el-form-item>
            <el-form-item v-if="state.applyInfoStatus.certOrg === '1'" label="发证机关：" prop="certOrg">
              <el-input v-model="form.certOrg" />
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="status.examStep === 2 && !applicationInformation[2].show" class="errorMessage"
          style="height: 2.0833rem; line-height: 2.0833rem">
          无相关信息选项，请点击下一步
        </div>
        <!-- 简介 -->
        <div v-if="status.examStep === 3 && applicationInformation[3].show">
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="150px"
            style="padding: 0 20%; text-align: center">
            <el-form-item label="简介：" prop="brief">
              <el-input v-model="form.brief" type="textarea" />
            </el-form-item>
          </el-form>
        </div>
        <div v-else-if="status.examStep === 3 && !applicationInformation[3].show" class="errorMessage"
          style="height: 2.0833rem; line-height: 2.0833rem">
          无相关信息选项，请点击下一步
        </div>
        <!-- 其他信息 -->
        <div v-if="status.examStep === 4 && applicationInformation[4].show">
          <el-row>
            <el-col>
              <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right"
                label-width="300px" style="padding: 0 20%; text-align: center">
                <el-form-item v-for="(item, index) in state.applyInfoStatus
                  .customizedConditionsList" :key="item.name" :label="item.content + '：'" style="width: 800px"
                  :prop="item.name">
                  <el-input v-if="item.type === '1'" v-model="form[item.name]" />
                  <el-radio-group v-else-if="item.type === '2'" v-model="form[item.name]" class="ml-4">
                    <el-radio v-for="(option, index) in item.data" :key="index" :label="option">{{ option }}</el-radio>
                  </el-radio-group>
                  <!-- checkbox -->
                  <el-checkbox-group v-else-if="item.type === '3'" v-model="form[item.name]" class="ml-4">
                    <el-checkbox v-for="(option, index) in item.data" :key="index" :label="option">{{ option
                    }}</el-checkbox>
                  </el-checkbox-group>
                  <!-- select -->
                  <el-select v-else-if="item.type === '4'" v-model="form[item.name]" placeholder="请选择">
                    <el-option v-for="(option, index) in item.data" :key="index" :label="option" :value="option" />
                  </el-select>
                  <div v-else-if="item.type === '5'">
                    <el-upload v-model:file-list="status.fileList[index]" class="upload-demo" action="''" multiple
                      :auto-upload="false" :limit="item.data[0]" :on-change="(file, fileList) => {
                        changeFileList(file, fileList, item.name);
                      }
                        ">
                      <el-button link type="primary">上传附件</el-button>
                    </el-upload>
                  </div>
                </el-form-item>
              </el-form></el-col>
          </el-row>
          <!-- <el-row v-else-if="state.applyInfoStatus">
            <el-col>
              <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right"
                label-width="150px" style="padding: 0 20%; text-align: center">
                <el-form-item v-if="state.applyInfoStatus.certName === '1'" label="资格证名称：" prop="certName">
                  <el-input v-model="form.certName" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.subjectName === '1'" label="各类学科：" prop="firstMajor">
                  <el-select v-model="form.subjectName" multiple filterable allow-create default-first-option
                    :reserve-keyword="false">
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.certTime === '1'" label="发证日期：" prop="certTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.certTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.certNum === '1'" label="资格证号：" prop="gualificaitonId">
                  <el-input v-model="form.gualificaitonId" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.certOrg === '1'" label="发证机关：" prop="gualificOrg">
                  <el-input v-model="form.gualificOrg" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.brief === '1'" label="简介内容：" prop="brief">
                  <el-input v-model="form.brief" />
                </el-form-item>
              </el-form>
            </el-col>
          </el-row> -->
        </div>
        <div v-else-if="status.examStep === 4 && !applicationInformation[4].show" class="errorMessage"
          style="height: 2.0833rem; line-height: 2.0833rem">
          无相关信息选项，请点击下一步
        </div>
        <!-- 上传照片 -->
        <div v-if="status.examStep === 5">
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="130px"
            style="padding: 0 20%; text-align: center">
            <el-form-item label="正面照：" class="image1Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimage1Url">
                <img v-if="state.image1Url" :src="state.image1Url" class="avatar" />
                <el-button v-if="state.image1Url" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
            <div style="padding-bottom: 40px">点击上传正面照</div>
            <el-form-item label="正面证件：" class="imageID1Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimageID1Url">
                <img v-if="state.imageID1Url" :src="state.imageID1Url" class="avatar" />
                <el-button v-if="state.imageID1Url" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
            <div style="padding-bottom: 40px; padding-left: 120px">
              点击上传身份证正面照
            </div>
            <el-form-item label="反面证件：" class="imageID2Url">
              <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                :on-change="handleAvatarChangeimageID2Url">
                <img v-if="state.imageID2Url" :src="state.imageID2Url" class="avatar" />
                <el-button v-if="state.imageID2Url" link>修改</el-button>
                <el-icon v-else class="avatar-uploader-icon">
                  <Plus />
                </el-icon>
              </el-upload>
            </el-form-item>
            <div style="padding-bottom: 40px; padding-left: 120px">
              点击上传身份证反面照
            </div>
          </el-form>
        </div>
        <!-- 预览提交 -->
        <div v-if="status.examStep === 6">
          <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right" label-width="160px">
            <div class="info-title">照片信息</div>
            <el-row>
              <el-col :span="12">
                <el-form-item label="正面照：" class="image1Url">
                  <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                    :on-change="handleAvatarChangeimage1Url">
                    <img v-if="state.image1Url" :src="state.image1Url" class="avatar" />
                    <el-button v-if="state.image1Url" link>修改</el-button>
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="enrollment-status">网上报名未完成</div>
                <div style="text-align: center; font-weight: bold; color: #ee6464">
                  未缴费
                </div>
              </el-col>
            </el-row>
            <div class="info-title">报考信息</div>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.sector === '1'" label="报考单位：" prop="sectorId">
                  <el-select v-model="form.sectorId" placeholder="请选择" @change="sectorChange">
                    <el-option v-for="(item, index) in state.sectorIdList" :key="item.id" :label="item.name"
                      :value="item.id" @click="sectorChange" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.department === '1'" label="报考部门：" prop="departmentId">
                  <el-select v-model="form.departmentId" placeholder="请选择" @change="departmentChange">
                    <el-option v-for="(item, index) in state.departmentIdList" :key="item.id" :label="item.name"
                      :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.post === '1'" label="报考岗位：" prop="stationId">
                  <el-select v-model="form.stationId" placeholder="请选择" @change="getMajorsAndDegrees">
                    <el-option v-for="(item, index) in state.stationList" :key="item.id" :label="item.name"
                      :value="item.id" />
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="info-title">基本信息</div>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.name === '1'" label="姓名：" prop="name">
                  <el-input v-model="form.name" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.gender === '1'" label="性别：" prop="sex">
                  <el-select v-model="form.sex" placeholder="请选择">
                    <el-option :key="0" label="男" value="0" />
                    <el-option :key="1" label="女" value="1" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.nation === '1'" label="民族：" prop="nation">
                  <el-input v-model="form.nation" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.politicStatus === '1'" label="政治面貌：" prop="politicsStatus">
                  <el-input v-model="form.politicsStatus" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.identityId === '1'" label="身份证号：" prop="cardId">
                  <el-input v-model="form.cardId" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.preEnrolReside === '1'" label="入学前户口所在地：" prop="oldAddress">
                  <el-input v-model="form.oldAddress" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.nowEnrolReside === '1'" label="现户口所在地：" prop="newAddress">
                  <el-input v-model="form.newAddress" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.fixedPhone === '1'" label="固定电话：">
                  <el-input v-model="form.telephone" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.mobilePhone === '1'" label="本人手机：" prop="mobile">
                  <el-input v-model="form.mobile" :disabled="true" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.familyAddress === '1'" label="家庭住址：" prop="familyAddress">
                  <el-input v-model="form.familyAddress" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.marryState === '1'" label="婚否：" prop="marryState">
                  <el-input v-model="form.marryState" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.marryChangeDate === '1'" label="婚迁日期：">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.marryChangeTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item>
              </el-col>
            </el-row>
            <div v-if="state.applyInfoStatus.firstDegree === '1'" class="info-title">
              学历
            </div>
            <el-row>
              <el-col :span="12" v-if="state.applyInfoStatus.firstDegree === '1'">
                <el-form-item label="学历：" prop="firstDegree">
                  <el-select v-model="form.firstDegree" placeholder="请选择">
                    <el-option :label="item" :value="item" v-for="(item, index) in state.degrees" :key="index" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstSchool === '1'">
                <el-form-item label="毕业院校：" prop="firstSchool">
                  <el-input v-model="form.firstSchool" />
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstMajor === '1'">
                <el-form-item label="所学专业：" prop="firstMajor">
                  <el-select v-model="form.firstMajor" placeholder="请选择" :reserve-keyword="false" filterable allow-create>
                    <el-option :label="item" :value="item" v-for="(item, index) in state.majors" :key="index" />
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstSdate === '1'"> <el-form-item label="入学日期："
                  prop="firstStartTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.firstStartTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item></el-col>

              <el-col :span="12" v-if="state.applyInfoStatus.firstEdate === '1'"> <el-form-item label="毕业日期："
                  prop="firstEndTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.firstEndTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item></el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstEduType === '1'"> <el-form-item label="培养类型："
                  prop="firstType">
                  <el-select v-model="form.firstType" placeholder="请选择">
                    <el-option :key="1" label="全日制" value="全日制" />
                    <el-option :key="2" label="非全日制" value="非全日制" />
                  </el-select>
                </el-form-item></el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstConclusion === '1'"><el-form-item label="毕业结论："
                  prop="firstCon">
                  <el-select v-model="form.firstCon" placeholder="请选择">
                    <el-option :key="1" label="毕业" value="毕业" />
                    <el-option :key="2" label="结业" value="结业" />
                    <el-option :key="3" label="肄业" value="肄业" />
                  </el-select>
                </el-form-item></el-col>
              <el-col :span="12" v-if="state.applyInfoStatus.firstCertId === '1'">
                <el-form-item label="毕业证号：" prop="firstNum">
                  <el-input v-model="form.firstNum" /> </el-form-item>
              </el-col>
            </el-row>
            <div v-if="state.applyInfoStatus.topDegree === '1'" class="info-title">
              最高学历
            </div>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.topDegree === '1'" label="最高学历：" prop="topDegree">
                  <el-select v-model="form.topDegree" placeholder="请选择">
                    <el-option :key="1" label="高中" value="高中" />
                    <el-option :key="2" label="专科" value="专科" />
                    <el-option :key="3" label="本科" value="本科" />
                    <el-option :key="4" label="硕士" value="硕士" />
                    <el-option :key="5" label="博士" value="博士" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topSchool === '1'" label="毕业院校：" prop="topSchool">
                  <el-input v-model="form.topSchool" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topMajor === '1'" label="所学专业：" prop="topMajor">
                  <el-input v-model="form.topMajor" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topSdate === '1'" label="入学日期：" prop="topStartTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.topStartTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.topEdate === '1'" label="毕业日期：" prop="topEndTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.topEndTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topEduType === '1'" label="学习形式：" prop="firstType">
                  <el-select v-model="form.topType" placeholder="请选择">
                    <el-option :key="1" label="全日制" value="全日制" />
                    <el-option :key="2" label="非全日制" value="非全日制" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topConclusion === '1'" label="毕业结论：" prop="topCon">
                  <el-select v-model="form.topCon" placeholder="请选择">
                    <el-option :key="1" label="毕业" value="毕业" />
                    <el-option :key="2" label="结业" value="结业" />
                    <el-option :key="3" label="肄业" value="肄业" />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.topCertId === '1'" label="毕业证号：" prop="topNum">
                  <el-input v-model="form.topNum" /> </el-form-item></el-col>
            </el-row>
            <div v-if="state.applyInfoStatus.certName === '1'" class="info-title">
              资格证书
              <!-- <span style="font-size: 12px; color: red;font-weight: 400;">（仅 <span style="font-weight: bold;">会计专业</span> 填写 <span style="font-weight: bold;">无</span> ，且发证日期选择 <span style="font-weight: bold;">报名时间</span> ）</span> -->
            </div>
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.certName === '1'" label="资格证名称：" prop="certName">
                  <el-input v-model="form.certName" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.subjectName === '1'" label="各类学科：" prop="subjectName">
                  <el-input v-model="form.subjectName" />
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.certNum === '1'" label="资格证号：" prop="certNum">
                  <el-input v-model="form.certNum" />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item v-if="state.applyInfoStatus.certTime === '1'" label="发证日期：" prop="certTime">
                  <el-config-provider :locale="zhcn">
                    <el-date-picker v-model="form.certTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                      value-format="YYYY-MM-DD" /></el-config-provider>
                </el-form-item>
                <el-form-item v-if="state.applyInfoStatus.certOrg === '1'" label="发证机关：" prop="certOrg">
                  <el-input v-model="form.certOrg" />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="info-title" v-if="state.applyInfoStatus.brief === '1'">简介</div>
            <el-row v-if="state.applyInfoStatus.brief === '1'">
              <el-col :span="12">
                <el-form-item label="简介：" prop="brief">
                  <el-input v-model="form.brief" type="textarea" />
                </el-form-item>
              </el-col>
            </el-row>
            <div class="info-title">其他信息</div>
            <el-row v-if="state.applyInfoStatus.customizedConditionsList?.length > 0">
              <el-col>
                <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right"
                  label-width="300px" style="padding: 0 20%; text-align: center">
                  <el-form-item v-for="(item, index) in state.applyInfoStatus
                    .customizedConditionsList" :key="item.id" :label="item.content + '：'" style="width: 800px"
                    :prop="item.name">
                    <el-input v-if="item.type === '1'" v-model="form[item.name]" />
                    <el-radio-group v-else-if="item.type === '2'" v-model="form[item.name]" class="ml-4">
                      <el-radio v-for="(option, index) in item.data" :key="index" :label="option">{{ option }}</el-radio>
                    </el-radio-group>
                    <!-- checkbox -->
                    <el-checkbox-group v-else-if="item.type === '3'" v-model="form[item.name]" @change="checkboxChange()">
                      <el-checkbox v-for="(option, index) in item.data" :key="index" :label="option">{{ option
                      }}</el-checkbox>
                    </el-checkbox-group>
                    <!-- select -->
                    <el-select v-else-if="item.type === '4'" v-model="form[item.name]" placeholder="请选择">
                      <el-option v-for="(option, index) in item.data" :key="index" :label="option" :value="option" />
                    </el-select>
                    <div v-else-if="item.type === '5'">
                      <el-upload v-model:file-list="status.fileList[index]" class="upload-demo" action="''" multiple
                        :auto-upload="false" :limit="item.data[0]" :on-change="(file, fileList) => {
                          changeFileList(file, fileList, item.name, index);
                        }
                          ">
                        <el-button link type="primary">上传附件</el-button>
                      </el-upload>
                    </div>
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row>
            <!-- <el-row v-else-if="state.applyInfoStatus">
              <el-col>
                <el-form :inline="true" :model="form" :rules="rules" ref="addForm" label-position="right"
                  label-width="150px" style="padding: 0 20%; text-align: center">
                  <el-form-item v-if="state.applyInfoStatus.certName === '1'" label="资格证名称：" prop="certName">
                    <el-input v-model="form.certName" />
                  </el-form-item>
                  <el-form-item v-if="state.applyInfoStatus.subjectName === '1'" label="各类学科：" prop="firstMajor">
                    <el-select v-model="form.subjectName" multiple filterable allow-create default-first-option
                      :reserve-keyword="false">
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="state.applyInfoStatus.certTime === '1'" label="发证日期：" prop="certTime">
                    <el-config-provider :locale="zhcn">
                      <el-date-picker v-model="form.certTime" type="date" placeholder="请选择" format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD" /></el-config-provider>
                  </el-form-item>
                  <el-form-item v-if="state.applyInfoStatus.certNum === '1'" label="资格证号：" prop="gualificaitonId">
                    <el-input v-model="form.gualificaitonId" />
                  </el-form-item>
                  <el-form-item v-if="state.applyInfoStatus.certOrg === '1'" label="发证机关：" prop="gualificOrg">
                    <el-input v-model="form.gualificOrg" />
                  </el-form-item>
                  <el-form-item v-if="state.applyInfoStatus.brief === '1'" label="简介内容：" prop="brief">
                    <el-input v-model="form.brief" />
                  </el-form-item>
                </el-form>
              </el-col>
            </el-row> -->
            <div class="info-title">身份证照片</div>
            <el-row>
              <el-col :span="12">
                <el-form-item label="正面证件：" class="imageID1Url">
                  <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                    :on-change="handleAvatarChangeimageID1Url">
                    <img v-if="state.imageID1Url" :src="state.imageID1Url" class="avatar" />
                    <el-button v-if="state.imageID1Url" link>修改</el-button>
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="反面证件：" class="imageID2Url">
                  <el-upload class="avatar-uploader" :action="''" :auto-upload="false" :show-file-list="false"
                    :on-change="handleAvatarChangeimageID2Url">
                    <img v-if="state.imageID2Url" :src="state.imageID2Url" class="avatar" />
                    <el-button v-if="state.imageID2Url" link>修改</el-button>
                    <el-icon v-else class="avatar-uploader-icon">
                      <Plus />
                    </el-icon>
                  </el-upload>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :push="6" :span="24">
                <el-form-item label="验证码：" style="width: 100%; padding-top: 20px" prop="verCode">
                  <el-input style="width: 120px" v-model="form.verCode" />
                  <img v-if="verData.img" style="height: 30px; width: 6vw; margin-left: 10px" @click="getVer"
                    :src="'data:image/jpg;base64,' + verData.img" />
                  <el-button v-else link @click="getVer">获取验证码</el-button>
                </el-form-item>
              </el-col>
            </el-row>
            <!-- <el-row style="margin: 20px 0">
              <el-col :push="10">
                <el-button style="width: 90px" type="primary" @click="onSubmit"
                  >提交</el-button
                >
              </el-col>
            </el-row> -->
            <el-form-item> </el-form-item>
          </el-form>
        </div>
        <!-- 审核资料 -->
        <div v-if="status.examStep === 7">
          <div class="submitSuccess">
            <div class="title">
              <el-icon style="color: #07cb1e; padding-right: 10px">
                <CircleCheck />
              </el-icon>提交成功
            </div>
            <p>请等待审核员处理结果</p>
            <p>
              审核状态：
              <span v-if="state.ApplyState.state === 0" style="color: #ef6466">未审核</span>
              <span v-if="state.ApplyState.state === 1" style="color: #ef6466">未通过不可报名</span>
              <span v-if="state.ApplyState.state === 2" style="color: #ef6466">未通过可以重新报名</span>
              <span v-if="state.ApplyState.state === 3" style="color: #07cb1e">已通过,未缴费</span>
              <span v-if="state.ApplyState.state === 4" style="color: #07cb1e">审核通过</span>
            </p>
            <p v-if="state.ApplyState.checkIdea">
              原因：{{ state.ApplyState.checkIdea }}
            </p>
          </div>
        </div>
        <!-- 报名完成 -->
        <div v-if="status.examStep === 8">
          <div class="submitSuccess">
            <div class="title">
              <el-icon style="color: #07cb1e; padding-right: 10px">
                <CircleCheck />
              </el-icon>报名完成
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <div v-if="status.examStep >= 0" style="padding-left: 40%; padding-bottom: 10px">
      <el-button :disabled="state.ApplyState.state !== ''" @click="backExamStep()"
        style="width: 80px; margin-right: 10px">上一步</el-button>
      <el-button v-if="state.ApplyState.state === 2" type="primary" @click="reload()"
        style="width: 80px; margin-right: 10px">
        重新报名
      </el-button>
      <el-button v-else type="primary" @click="nextExamStep(addForm)" style="width: 80px; margin-right: 10px"
        :loading="state.nextStatus">
        <span v-if="status.examStep === 6">提交</span>

        <span v-else-if="status.examStep === 8">返回首页</span>
        <span v-else-if="status.examStep === 7 && state.ApplyState.state !== 5">下一步</span>
        <span v-else>下一步</span>
      </el-button>
    </div>
  </div>
  <div class="score-query" v-if="!status.enrollStatus">
    <el-row class="score-query-title">
      <el-col>
        <div style="position: relative">
          <el-icon class="btn" @click="$router.push('/protal')">
            <ArrowLeftBold />
          </el-icon>
        </div>
        考生入口
      </el-col>
    </el-row>
    <el-row v-if="state.errorMessage">
      <el-col>
        <div class="errorMessage">{{ state.errorMessage }}</div>
      </el-col>
    </el-row>
    <el-row v-else class="message-content">
      <el-col>
        <div style="height: 75vh; overflow: hidden">
          <div v-for="(item, index) in rowsList" :key="index" :class="item.isApply === 0
            ? 'message-item'
            : 'message-item  message-item-disabled'
            ">
            <div class="message-item-left">
              <div>考试名称：{{ item.examName }}</div>
              <div>
                报名时间：<span>{{
                  moment(item.examStartTime).format(
                    "YYYY 年 MM 月 DD 日 HH:mm"
                  )
                }}
                  ~
                  {{
                    moment(item.examEndTime).format("YYYY 年 MM 月 DD 日 HH:mm")
                  }}</span>
              </div>
            </div>

            <div class="message-item-right">
              <el-button type="primary" v-if="item.isApply === 0" @click="enroll(item)">去报名</el-button>
              <el-button type="primary" v-if="item.isApply === 1" disabled>未开放</el-button>
              <el-button type="primary" v-if="item.isApply === 2" disabled>未开放</el-button>
              <el-button type="primary" v-if="item.isApply === 3" @click="enroll(item)">已报名</el-button>
            </div>
          </div>
        </div>
        <el-pagination :page-size="pagination.pageSize" layout="prev, pager, next" :total="pagination.total"
          style="float: right" @current-change="handleCurrentChange" />
      </el-col>
    </el-row>
  </div>
</template>
<script setup>
import { post, get } from "@/api/api";
import {
  onMounted,
  onUpdated,
  reactive,
  ref,
  watch,
  getCurrentInstance,
  defineEmits,
} from "@vue/runtime-core";
import { useStore } from "vuex";
import { ElMessage } from "element-plus";
import { ArrowLeftBold, CircleCheck } from "@element-plus/icons-vue";
import moment from "moment";
import { useRouter, useRoute } from "vue-router";
import zhcn from "element-plus/lib/locale/lang/zh-cn";
const emit = defineEmits(["back"]);
const router = useRouter();
const route = useRoute();
const currentInstance = getCurrentInstance();
let store = useStore();
onMounted(() => {
  getExamListByTimeAndState();
});
onUpdated(() => { });

//data
let addForm = ref();
let state = reactive({
  examInfo: {}, // 考试详情
  examContent: "", // 考试注意事项
  examProtocol: {}, // 考试协议
  applyInfoStatus: {}, // 填报信息状态
  sectorIdList: [], // 报考单位options
  departmentIdList: [], // 报考部门options
  stationList: [], // 报考岗位options
  image1Url: "", // 正面照路径
  imageID1Url: "", // 身份证正面照路径
  imageID2Url: "", // 身份证反面照路径
  ApplyState: "", // 审批状态
  errorMessage: "",
  majors: [],
  degrees: [],
  nextStatus: false
});
let form = reactive({
  sectorId: "", // 报考单位
  departmentId: "", // 报考部门
  stationId: "", // 报考岗位
  name: "", // 考生姓名
  sex: undefined, // 性别
  nation: "", // 民族
  politicsStatus: "", // 政治面貌
  cardId: "", // 身份证号
  oldAddress: "", // 入学前户口所在地
  newAddress: "", // 现户口所在地
  telephone: "", // 固定电话
  mobile: store.state.user.userId, // 本人手机：
  familyAddress: "", // 家庭住址
  marryState: "", // 婚否
  marryChangeTime: "", // 婚迁日期
  firstDegree: "", // 学历
  firstSchool: "", // 毕业院校
  firstMajor: "", // 所学专业
  firstStartTime: "", // 入学日期
  firstEndTime: "", // 毕业日期
  firstType: "", // 培养类型
  firstCon: "", // 毕业结论
  firstNum: "", // 毕业证号
  examId: "", // 考试ID
  verCode: "", // 验证码
  verCodeId: "", // 验证码ID
  subjectName: "", // 各类学科
  brief: "", //简介
});
const rules = reactive({
  sectorId: [{ required: true, message: "请选择报考单位", trigger: "change" }],
  departmentId: [
    { required: true, message: "请选择报考部门", trigger: "change" },
  ],
  stationId: [{ required: true, message: "请选择报考岗位", trigger: "change" }],
  name: [{ required: true, message: "请填写姓名", trigger: "blur" }],
  sex: [{ required: true, message: "请选择性别", trigger: "change" }],
  nation: [{ required: true, message: "请填写内容", trigger: "blur" }],
  politicsStatus: [{ required: true, message: "请填写内容", trigger: "blur" }],
  cardId: [
    {
      required: true,
      message: "身份证号格式不正确",
      trigger: "blur",
      pattern:
        /^[1-9]\d{5}(?:18|19|20)\d{2}(?:0[1-9]|10|11|12)(?:0[1-9]|[1-2]\d|30|31)\d{3}[\dXx]$/,
    },
  ],
  oldAddress: [{ required: true, message: "请填写内容", trigger: "blur" }],
  newAddress: [{ required: true, message: "请填写内容", trigger: "blur" }],
  mobile: [
    {
      required: true,
      message: "请填写手机号",
      trigger: "blur",
      pattern: /^(?:(?:\+|00)86)?1[3-9]\d{9}$/,
    },
  ],
  familyAddress: [{ required: true, message: "请填写内容", trigger: "blur" }],
  marryState: [{ required: true, message: "请填写内容", trigger: "blur" }],
  firstDegree: [
    { required: true, message: "请选择第一学历", trigger: "change" },
  ],
  topDegree: [{ required: true, message: "请选择最高学历", trigger: "change" }],
  firstSchool: [{ required: true, message: "请填写内容", trigger: "blur" }],
  topSchool: [{ required: true, message: "请填写内容", trigger: "blur" }],
  firstMajor: [{ required: true, message: "请填写内容", trigger: "blur" }],
  topMajor: [{ required: true, message: "请填写内容", trigger: "blur" }],
  firstStartTime: [
    { required: true, message: "请填写入学日期", trigger: "blur" },
  ],
  topStartTime: [
    { required: true, message: "请填写入学日期", trigger: "blur" },
  ],
  certTime: [{ required: true, message: "请填写发证日期", trigger: "blur" }],

  firstEndTime: [
    { required: true, message: "请填写毕业日期", trigger: "blur" },
  ],
  topEndTime: [{ required: true, message: "请填写毕业日期", trigger: "blur" }],

  firstType: [{ required: true, message: "请选择学习形式", trigger: "change" }],
  topType: [{ required: true, message: "请选择学习形式", trigger: "change" }],
  firstCon: [{ required: true, message: "请选择毕业结论", trigger: "change" }],
  topCon: [{ required: true, message: "请选择毕业结论", trigger: "change" }],
  firstNum: [{ required: true, message: "请填写毕业证号", trigger: "blur" }],
  topNum: [{ required: true, message: "请填写毕业证号", trigger: "blur" }],
  verCode: [{ required: true, message: "请填写验证码", trigger: "blur" }],
  certName: [{ required: true, message: "请填写内容", trigger: "blur" }],
  gualificaitonId: [{ required: true, message: "请填写内容", trigger: "blur" }],
  gualificOrg: [{ required: true, message: "请填写内容", trigger: "blur" }],
  // brief: [{ required: true, message: "请填写内容", trigger: "blur" }],
  certName: [{ required: true, message: "请填写内容", trigger: "blur" }],
  subjectName: [{ required: true, message: "请填写内容", trigger: "blur" }],
  certNum: [{ required: true, message: "请填写内容", trigger: "blur" }],
  certTime: [{ required: true, message: "请填写内容", trigger: "blur" }],
  certOrg: [{ required: true, message: "请填写内容", trigger: "blur" }],
});
// list数据
let rowsList = ref([]);
// 页面状态
let status = reactive({
  enrollStatus: false,
  examStep: -2,
  reload: false,
  fileList: []
});
// 分页数据
let pagination = reactive({
  pageSize: 6,
  pageNo: 1,
  total: 0,
});
watch(
  () => status.examStep,
  (val) => {
    console.log(val);
    if (val === 0) {
      if (status.reload) {
        getSecondInfo();
      } else {
      }
    }
    if (val === 1) {
      if (status.reload) {
      } else {
        getInfo();
      }
    }

    // 预览
    if (val === 6) {
      verData.img = "";
    }

    if (val === 7) {
      getApplyState();
    }
    if (val === 8 && state.ApplyState.state !== 4) {
      router.push("/portal");
    }
    if (val === 9) {
      router.push("/portal");
    }
  }
);

// methods
// 获取考试列表
const getExamListByTimeAndState = async () => {
  let res = await get("/examApply/getExamListByTimeAndState", pagination);
  if (res.code === 200) {
    rowsList.value = res.result.list;
    pagination.total = res.result.total;
    if (res.result.list.length == 0) {
      state.errorMessage = res.message;
    } else {
      state.errorMessage = "";
    }
  } else {
    ElMessage.error(res.message);
  }
};
// 报名
const enroll = (row) => {
  console.log(row)
  state.examInfo = row;
  state.examContent = row.examContent;
  status.examStep = -2;
  if (row.isApply === 3) {
    status.examStep = 7;
    status.enrollStatus = true;
    return
  }
  if (!state.examContent) {
    status.examStep = -1
    getExamProtocol(state.examInfo);
  } else {
    status.examStep = -2;
  }
  status.enrollStatus = true;
};
// 获取考试协议
const getExamProtocol = async () => {
  let res = await get("/examApply/getExamProtocol", {
    examId: state.examInfo.examId,
  });
  state.examProtocol = res.result;
  if (status.examStep === 7) return
  if (!state.examProtocol.proContent) {
    getOptions();
    getApplyInfo();
    status.examStep = 0;
  } else {
    status.examStep = -1;
  }
};
// 开始报名
const startEnrollment = () => {
  getExamProtocol(state.examInfo);
  status.examStep = -1;
};
// 开始填报
const startFilling = () => {
  getOptions();
  getApplyInfo();
  status.examStep = 0;
};
// 获取个人信息
function getInfo () {
  get("/examApply/user/getInfo").then((res) => {
    console.log(res);
    Object.assign(form, res.result);
    form.firstDegree = "";
    form.firstMajor = "";
  });
}
// 第二次报名 获取上次报名信息
function getSecondInfo () {
  get("/examApply/queryByExamId", { examId: state.examInfo.examId }).then(
    async (res) => {
      Object.assign(form, res.result);

      let departmentIdList = await get(
        "/examApply/getListByExamIdAndSectorId",
        {
          examId: state.examInfo.examId,
          sectorId: form.sectorId,
        }
      );
      state.departmentIdList = departmentIdList.result;
      let stationList = await get("/examApply/getListByExamIdAndDepartId", {
        examId: state.examInfo.examId,
        departmentId: form.departmentId,
      });
      state.stationList = stationList.result;

      state.applyInfoStatus.customizedConditionsList.forEach((element) => {
        if (element.type === "3") {
          form[element.name] = form[element.name].split(",");
        }
      });
    }
  );
}
let applicationInformation = reactive([
  // 报考信息
  {
    show: true,
    sector: '0', // 报考单位
    department: '0', // 报考部门
    post: '0' // 报考岗位
  },
  // 基本信息
  {
    show: true,
    name: '0', // 姓名
    gender: '0', // 性别
    nation: '0', // 民族
    politicStatus: '0', // 政治面貌
    identityId: '0', // 身份证号
    preEnrolReside: '0', // 入学前户口所在地
    nowEnrolReside: '0', // 现户口所在地
    fixedPhone: '0', // 固定电话
    mobilePhone: '0', // 手机号码
    familyAddress: '0', // 家庭住址
    marryState: '0', // 婚否
    marryChangeDate: '0', // 婚迁日期
  },
  // 学历信息
  {
    show: true,
    firstDegree: '0', // 第一学历
    firstSchool: '0', // 第一学历毕业院校
    firstMajor: '0', // 第一学历专业
    firstSdate: '0', // 第一学历入学时间
    firstEdate: '0', // 第一学历毕业日期
    firstEduType: '0', // 第一学历培养类型
    firstConclusion: '0', // 第一学历毕业结论
    firstCertId: '0', // 第一学历证书编号
    topDegree: '0', // 最高学历
    topSchool: '0', // 最高学历毕业院校
    topMajor: '0', // 最高学历专业
    topSdate: '0', // 最高学历入学时间
    topEdate: '0', // 最高学历毕业日期
    topEduType: '0', // 最高学历培养类型
    topConclusion: '0', // 最高学历毕业结论
    topCertId: '0', // 最高学历证书编号
    certName: '0', // 证书名称
    subjectName: "0", // 各类学科
    certNum: '0', // 资格证号
    certTime: '0', // 资格证书发证时间
    certOrg: '0' // 资格证书发证机构
  },
  // 简介
  {
    show: true,
    brief: '0' // 简介
  },
  // 其他信息
  {
    show: true,
    applyInfoStatus: '0'
  }
]);
// 获取填报信息
const getApplyInfo = async () => {
  // 报考单位
  let res = await get("/examApply/applyInfo", {
    examId: state.examInfo.examId,
  });
  state.applyInfoStatus = res.result;

  state.applyInfoStatus.customizedConditionsList.forEach((element) => {
    if (element.type === "3") {
      form[element.name] = [];
    }
    if (element.required === 1) {
      rules[element.name] = [
        { required: true, message: "请填写内容", trigger: "change" },
      ];
    }
  });
  let strList = Object.keys(state.applyInfoStatus)

  strList.forEach(str => {
    applicationInformation.forEach(part => {
      let strPart = Object.keys(part)
      strPart.forEach((item) => {
        if (item === str) {
          part[str] = state.applyInfoStatus[str]
        }
      })

    })
  })
  if (state.applyInfoStatus.customizedConditionsList.length) {
    applicationInformation[4].applyInfoStatus = '1'
  }
  applicationInformation.forEach(item => {
    Object.values(item).includes('1') ? item.show = true : item.show = false
  })
  console.log(applicationInformation);
};

// 获取第一步options
const getOptions = async () => {
  // 获取报考单位
  let sectorIdList = await get("/examApply/getListByExamId", {
    examId: state.examInfo.examId,
  });
  state.sectorIdList = sectorIdList.result;
};

async function sectorChange () {
  // 报考单位change
  let departmentIdList = await get("/examApply/getListByExamIdAndSectorId", {
    examId: state.examInfo.examId,
    sectorId: form.sectorId,
  });
  form.departmentId = "";
  form.stationId = "";
  form.firstDegree = "";
  form.firstMajor = "";
  state.departmentIdList = departmentIdList.result;
}
async function departmentChange () {
  // 报考部门change
  let stationList = await get("/examApply/getListByExamIdAndDepartId", {
    examId: state.examInfo.examId,
    departmentId: form.departmentId,
  });
  form.stationId = "";
  form.firstDegree = "";
  form.firstMajor = "";
  state.stationList = stationList.result;
}
// 报考岗位change
function getMajorsAndDegrees () {
  post("/examApply/getMajorsAndDegrees", {
    examId: state.examInfo.examId,
    postId: form.stationId,
  }).then((res) => {
    state.majors = res.result.majors;
    state.degrees = res.result.degrees;
  });
  form.firstDegree = "";
  form.firstMajor = "";
}

function handleAvatarChangeimage1Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.image1Url = URL.createObjectURL(file.raw);
    uploadImg(file, "image1Url");
  }
}
function handleAvatarChangeimageID1Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.imageID1Url = URL.createObjectURL(file.raw);
    uploadImg(file, "imageID1Url");
  }
}
function handleAvatarChangeimageID2Url (file, fileList) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传图片只能是 JPG/JPEG/PNG 格式!");
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("图片大小不能超过100KB！");
    return false;
  } else {
    state.imageID2Url = URL.createObjectURL(file.raw);
    uploadImg(file, "imageID2Url");
  }
}
//文件上传操作
async function uploadImg (file, imgType) {
  let attachFile = new FormData();
  attachFile.append("attachFile", file.raw);
  attachFile.append("examId", state.examInfo.examId);
  let res;
  // 正面照
  if (imgType === "image1Url") {
    res = await post("/examApply/uploadPicture", attachFile);
  }
  // 身份证正面照
  if (imgType === "imageID1Url") {
    res = await post("/examApply/uploadIdCardFront", attachFile);
  }
  // 身份证背面照
  if (imgType === "imageID2Url") {
    res = await post("/examApply/uploadIdCardBack", attachFile);
  }
  if (res.code == "200") {
    ElMessage.success("图片上传成功");
  } else {
    ElMessage.error("图片上传失败");
    ElMessage.error(res.message);
  }
}

// 查看报名审核状态
function getApplyState () {
  get("/examApply/getApplyState", { examId: state.examInfo.examId }).then(
    (res) => {
      state.ApplyState = res.result;
      console.log(state.ApplyState);
      // if (res.result === 4) {
      //   status.examStep = 8
      // }
    }
  );
}
function resetForm (addForm) {
  addForm.resetFields();
}

// 上一步
const backExamStep = () => {
  status.examStep--;
};
// 下一步
const nextExamStep = (addForm) => {
  // 提交表单
  if (status.examStep === 6) {
    
    addForm.validate((valid) => {
      let stime = moment(form.firstStartTime).valueOf();
      let etime = moment(form.firstEndTime).valueOf();
      let stime2 = moment(form.topStartTime).valueOf();
      let etime2 = moment(form.topEndTime).valueOf();

      if (state.image1Url && state.imageID1Url && state.imageID2Url) {
        if (valid) {
          if (isNaN(stime) && stime >= etime) {
            ElMessage.error(
              "毕业日期不能在入学日期之前，请检查填写内容是否正确"
            );
            return false;
          }
          if (isNaN(stime2) && stime2 >= etime2) {
            ElMessage.error(
              "毕业日期不能在入学日期之前，请检查填写内容是否正确"
            );
            return false;
          }
          getVer();
          form.examId = state.examInfo.examId;

          for (const key in form) {
            if (Object.hasOwnProperty.call(form, key)) {
              if (form[key] instanceof Array) {
                form[key] = form[key].toString();
                console.log(form);
              }
            }
          }
          state.nextStatus = true
          console.log(state.nextStatus)
          post("/examApply/apply", { ...form }).then((res) => {
            
            
            setTimeout(() => {
              state.nextStatus = false
            }, 3000);
            if (res.code === 200) {
              state.nextStatus = false
              ElMessage.success("提交成功");
              getApplyState();
              status.examStep++;
              return false;
            } else {
              state.nextStatus = false
              ElMessage.error(res.message);
              state.applyInfoStatus.customizedConditionsList.forEach(
                (element) => {
                  if (element.type === "3") {
                    form[element.name] = form[element.name].split(",");
                  }
                }
              );
              return false;
            }
          });
        } else {
          ElMessage.error("请检查填写内容是否正确");
        }
      } else {
        ElMessage.error("请上传正面照/身份证照片");
      }
    });
  }
  if (status.examStep !== 6) {
    status.examStep++;
  }

};

// 验证码
let verData = reactive({});
const getVer = async () => {
  let res = await get("/verCode/get");
  Object.assign(verData, res.result);
  form.verCodeId = res.result.imgUUID;
};

function checkboxChange (val) {
  console.log(val);
}
// 分页切换
const handleCurrentChange = (num) => {
  pagination.pageNo = num;
  getExamListByTimeAndState();
};
// 返回
const back = () => {
  emit("back");
  status.enrollStatus = false;
};

function changeFileList (file, fileList, name, index) {
  const isJPG = file.raw.type === "image/jpg";
  const isJPEG = file.raw.type === "image/jpeg";
  const isPNG = file.raw.type === "image/png";
  if (!isJPEG && !isJPG && !isPNG) {
    ElMessage.error("上传附件只能是 JPG/JPEG/PNG 格式!");
    status.fileList[index] = [];
    return false;
  }
  const isLt100K = file.size / 1024;
  if (isLt100K > 100) {
    ElMessage.error("附件大小不能超过100KB！");
    status.fileList[index] = [];
    return false;
  } else {
    let arr = [];
    console.log(form);
    fileList.forEach(async (element) => {
      console.log(element);
      getBase64(element.raw).then((res) => {
        arr.push(res);
      });
    });
    setTimeout(() => {
      form[name] = arr.join("|");
    }, 300);
    console.log(form);
    console.log(status.fileList);
  }
}
function getBase64 (file) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader();
    let imgResult = "";
    reader.readAsDataURL(file);
    reader.onload = function () {
      imgResult = reader.result;
    };
    reader.onerror = function (error) {
      reject(error);
    };
    reader.onloadend = function () {
      resolve(imgResult);
    };
  });
}
function reload () {
  state.ApplyState = "";
  status.examStep = -2;
  status.reload = true;
}

defineExpose({
  state,
  status,
  enroll,
});
</script>
<style lang="scss" scoped>
@import "../../../../utils/btn.css";

.score-query {
  height: 89vh;
  background-color: #ffffff;
}

.message-content {
  min-height: 75vh;
  padding: 0 0.3125rem;

  .el-input {
    // width: 1.1146rem;
  }

  :deep(.el-form-item__content) {
    // width: 1.1146rem;
  }
}

.score-query-title {
  text-align: center;
  height: 0.3125rem;
  line-height: 0.3125rem;
  font-size: 0.1563rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  border-bottom: 0.0052rem solid #e5e5e5;
  margin-bottom: 0.1042rem;

  .btn {
    position: absolute;
    left: 0;
    width: 0.3125rem;
    height: 0.3125rem;
    font-size: 0.0833rem;
    cursor: pointer;
  }
}

.message-item {
  display: flex;
  justify-content: space-between;
  // height: 0.4896rem;
  height: 10vh;
  background: #ffffff;
  border: 1px solid #d2d2d2;
  border-radius: 0.0208rem;
  margin-bottom: 0.1042rem;
  // cursor: pointer;
  padding-left: 0.1042rem;
  border-left: 4px solid #097afa;

  .message-item-left {
    width: 90%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;

    div {
      font-size: 0.0938rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
      padding: 0.0313rem 0;
    }

    div:nth-child(2) span {
      font-size: 0.0729rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #666666;
    }
  }

  .message-item-right {
    width: 10%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding-right: 0.1042rem;
  }
}

.message-item-disabled {
  border-left: 0.0208rem solid #d2d2d2;
}

// .message-item:hover {
//   background-color: #eee;
// }
.el-button {
  margin: 0;
  // background-color: #097afa;
}

.is-disabled,
.is-disabled:hover {
  border: none;
  color: #999999;
  background-color: #eeeeee;
}

:deep(.el-steps) {
  .is-process {
    color: #409eff;
    border-color: #409eff;
  }
}

.avatar-uploader .el-upload {
  border: 0.0052rem dashed var(--el-border-color);
  border-radius: 0.0313rem;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  align-items: flex-end;
  transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
  font-size: 0.1458rem;
  color: #8c939d;
  width: 0.5938rem;
  height: 0.7396rem;
  text-align: center;
  border: 0.0052rem solid #ccc;
}

.avatar {
  width: 0.5938rem;
  height: 0.7396rem;
}

.image1Url,
.imageID1Url,
.imageID2Url {
  margin-bottom: 0;
}

.imageID1Url,
.imageID2Url {
  .el-icon.avatar-uploader-icon {
    width: 1.2396rem;
    height: 0.7396rem;
    border: 0.0052rem solid #ccc;
  }

  .avatar {
    width: 1.2396rem;
    height: 0.7396rem;
  }
}

.info-title {
  padding-left: 0.0521rem;
  border-left: 0.0208rem solid #097afa;
  font-size: 0.0938rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #333333;
  margin-bottom: 0.0521rem;
}

.enrollment-status {
  text-align: center;
  font-size: 0.1042rem;
  padding-bottom: 0.0521rem;
}

.submitSuccess {
  padding-left: 45%;

  .title {
    font-size: 0.1354rem;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    padding: 0.1042rem 0;

    .el-icon {
      vertical-align: middle;
      padding-bottom: 0.0208rem;
    }
  }

  p {
    font-size: 0.0729rem;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 0.1563rem;
  }
}

.errorMessage {
  height: 3.6979rem;
  line-height: 3.6979rem;
  font-size: 24px;
  color: #ccc;
  text-align: center;
}
</style>
